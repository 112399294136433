import { useState } from "react";
import { Modal } from "react-bootstrap";
import { UPDATE_ITEMS } from "../../home/apollo";
import { getStaffLogin, messageError, messageSuccess, messageWarning } from "../../../helper";
import { useMutation } from "@apollo/client";
import Notiflix from "notiflix";
import moment from "moment";

export default function SentBackShop({ id, onReload }) {
  const [show, setShow] = useState(false);
  const [note, setNote] = useState();
  const DateTime = moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
  const user_Branch = getStaffLogin();
  const [updateItems, { loading }] = useMutation(UPDATE_ITEMS);
  const _updateSentBackShop = () => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຢືນຢັນແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        if (!note || note.trim() === '') {
          messageWarning("ກະລຸນາປ້ອນຂໍ້ມູນກອນ");
          return false;
        }
        try {
          const _updatePayroll = await updateItems({
            variables: {
              data: {
                itemStatus: "WAITING_BACK_SHOP",
                note: String(note),
                deliveryCompletedDate: DateTime,
                sentBranch: user_Branch?.branch?._id
              },
              where: {
                _id: parseInt(id),
              },
            },
          });

          if (_updatePayroll) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            onReload(loading);
            setShow(false);
            setNote("");
          }
        } catch (error) {
          console.log(error);
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <div>
      <button className="btn btn-sm btn-warning mt-1 p-1 w-100" onClick={() => { setShow(true); setNote("") }}>
        <i className="fa-solid fa-circle-check me-1 left" />ສົ່ງຄືນເເມ່ຄ້າ
      </button>
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        backdrop="static"
        size="lx"
        className="h-100"
      >
        <Modal.Header closeButton>ກ້ຽມສົ່ງຄືນເເມ່ຄ້າ</Modal.Header>
        <Modal.Body>
          <div>
            <textarea
              type="textarea"
              className="form-control"
              onChange={(e) => setNote(e.target.value)}
              style={{
                height: "200px",
                fontSize: "16px",
                color: "#999"
              }}
              placeholder="ກ້ຽມສົ່ງຄືນເເມ່ຄ້າ"
            />
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button className="btn btn-success" onClick={() => _updateSentBackShop()} disabled={!note}>
              ຢຶນຢັນສົ່ງຄືນເເມ່ຄ້າ
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}