import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import {
  currency,
  detectPhoneNumber,
  formatDateDash,
  getStaffLogin,
  ItemStatus,
  toDayDash,
} from "../../../helper";
import BottomNav from "../../../layouts/BottomNav";
import whatsapp from "../../../icon/whatsapp.svg";
import { useLazyQuery } from "@apollo/client";
import { DETAIL_ITEMS_COMPLETED } from "../../../routes/app";
import { LIST_SHIPPER_ITEM } from "../../home/apollo";
import _ from "lodash";
import SumCommission from "./SumCommission";
import { Card, Col, Row } from "react-bootstrap";
import PACKAGE_LOGO from "../../../img/package.png";
import NODATA from "../../../img/Nodata.png";

export default function ItemCompleted() {
  const { history } = useReactRouter();
  const [startDateValue, setStartDateValue] = useState(toDayDash());
  const [endDateValue, setEndDateValue] = useState(new Date());
  const [searchValue, setSearchValue] = useState();
  const [eventSearch, setEventSearch] = useState();
  const [_item, setResult] = useState();
  const [_itemOrigin, setResultOrigin] = useState();
  const user = getStaffLogin();
  const [fetchData, { data: result }] = useLazyQuery(LIST_SHIPPER_ITEM, {
    fetchPolicy: "cache-and-network",
  });

  const [fetchDataOrigin, { data: resultOrigin }] = useLazyQuery(
    LIST_SHIPPER_ITEM,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          shipper: user?._id,
          trackingId: searchValue ? searchValue : undefined,
          deliveryCompletedDateBetween: [startDateValue, endDateValue],
          itemStatusIn: ["COMPLETED", "WAITING_BACK_SHOP", "SENT_BACK_SHOP"],
          backwardOrigin: 0,
        },
        orderBy: "DESC",
        limit: 0,
      },
    });

    fetchDataOrigin({
      variables: {
        where: {
          shipperOrigin: user?._id,
          trackingId: searchValue ? searchValue : undefined,
          deliveryCompletedDateBetween: [startDateValue, endDateValue],
          itemStatusIn: ["COMPLETED", "WAITING_BACK_SHOP", "SENT_BACK_SHOP"],
          backwardOrigin: 1,
        },
        orderBy: "DESC",
        limit: 0,
      },
    });
  }, [startDateValue, endDateValue, eventSearch]);

  useEffect(() => {
    if (result) {
      setResult(result?.items?.data);
    }
    setResultOrigin(resultOrigin?.items?.data);
  }, [result, resultOrigin]);

  const total = result?.items?.total;
  const totalOrigin = resultOrigin?.items?.total;
  const totalAll = total + totalOrigin;
  const message = "ສະບາຍດີ";

  const _itemValueKIP = _.sumBy(_item, "realValueKIP");
  const _itemValueTHB = _.sumBy(_item, "realValueTHB");
  const _itemValueUSD = _.sumBy(_item, "realValueUSD");
  const _itemFilter = _item?.filter((_data) => _data?.chargeOnShop === 0);
  const _deliveryPrice = _.sumBy(_itemFilter, "realDeliveryPrice");

  const _itemOriginValueKIP = _.sumBy(_itemOrigin, "itemValueKIP");
  const _itemOriginValueTHB = _.sumBy(_itemOrigin, "itemValueTHB");
  const _itemOriginValueUSD = _.sumBy(_itemOrigin, "itemValueUSD");
  const _deliveryOriginPrice = _.sumBy(_itemOrigin, "realDeliveryPrice");

  const _itemValueAllKIP = _itemValueKIP + _itemOriginValueKIP;
  const _itemValueAllTHB = _itemValueTHB + _itemOriginValueTHB;
  const _itemValueAllUSD = _itemValueUSD + _itemOriginValueUSD;
  const _deliveryPriceAll = _deliveryPrice + _deliveryOriginPrice + _itemValueAllKIP;
  const _deliveryPriceShipper = _deliveryPrice + _deliveryOriginPrice;

  return (
    <>
      <div className=" body-content-lg" style={{ marginTop: 50 }}>
        <div className="option-section">
          <div className="col-12">
            <div className="listed-detail">
              <div className="row">
                <div className="col-6 mb-1">
                  <input
                    type="date"
                    className="form-control form-control-md"
                    value={formatDateDash(startDateValue)}
                    onChange={(e) => {
                      setStartDateValue(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6 mb-1">
                  <input
                    type="date"
                    className="form-control form-control-md"
                    value={formatDateDash(endDateValue)}
                    onChange={(e) => {
                      setEndDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control form-control-md"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  value={searchValue}
                  placeholder="ຄົ້ນຫາ Tracking ID"
                />
                <button
                  type="button"
                  className="btn-dark"
                  style={{
                    width: "70px",
                    borderTopRightRadius: "0.25rem",
                    borderBottomRightRadius: "0.25rem",
                  }}
                  onClick={(e) => setEventSearch(!eventSearch)}
                >
                  <i className="icon-search1" />
                </button>
              </div>
            </div>
            <div style={{ color: "black" }}>
              <Card
                className="p-1 mt-2"
                style={{ boxShadow: "0px 3px 5px 3px rgba(0, 0, 0, 0.15)" }}
              >
                <h3 className="bold-nato-sans text-center">ລາຍງານລວມ</h3>
                <div className="bold-nato-sans" style={{ color: "grey" }}>
                  ເງິນເກັບໄດ້ຈິງ: {currency(_itemValueAllKIP || 0)} ₭
                </div>
                <div className="bold-nato-sans" style={{ color: "grey" }}>
                  ເງິນເກັບໄດ້ຈິງ: {currency(_itemValueAllTHB || 0)} ฿
                </div>
                <div className="bold-nato-sans" style={{ color: "grey" }}>
                  ເງິນເກັບໄດ້ຈິງ: {currency(_itemValueAllUSD || 0)} $
                </div>
                <div className="bold-nato-sans" style={{ color: "grey" }}>
                  ຄ່າບໍລິການ: {currency(_deliveryPriceShipper || 0)} ₭
                </div>
                <div className="bold-nato-sans" style={{ color: "grey" }}>
                  ລວມເງີນທີຕ້ອງມອບ: {currency(_deliveryPriceAll || 0)} ₭
                </div>
              </Card>
              <Card
                className="p-1 mt-2"
                style={{ boxShadow: "0px 3px 5px 3px rgba(0, 0, 0, 0.15)" }}
              >
                <SumCommission
                  startDate={startDateValue}
                  endDate={endDateValue}
                />
              </Card>
              <div
                className="mt-1 text-muted"
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Noto Sans Lao', sans-serif",
                }}
              >
                ຈຳນວນ {totalAll || 0} ລາຍການ
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="section">
          <div className="transactions ">
            {(_itemOrigin && _itemOrigin.length > 0) ||
            (_item && _item.length > 0) ? (
              <>
                {_itemOrigin?.map((item) => (
                  <Card
                    className="shadow mb-1"
                    key={item?._id}
                    onClick={() =>
                      history.push(`${DETAIL_ITEMS_COMPLETED}/${item?._id}`)
                    }
                  >
                    <Row className="p-1">
                      <Col className="col-3 d-flex flex-column align-items-center">
                        <img
                          src={PACKAGE_LOGO}
                          alt="PACKAGE_LOGO"
                          style={{ width: 70 }}
                        />
                        <h6 className="text-muted text-nowrap">
                          {formatDateDash(item?.deliveryCompletedDate)}
                        </h6>
                        {item?.itemStatus === "COMPLETED" ? (
                          <small className="text-success">
                            {ItemStatus(item?.itemStatus)}
                          </small>
                        ) : (
                          <small className="text-danger">
                            {ItemStatus(item?.itemStatus)}
                          </small>
                        )}
                      </Col>
                      <Col className="col-9">
                        <div className="col-12 d-flex">
                          <strong
                            style={{
                              fontWeight: "bold",
                              color: "grey",
                              fontFamily: "'Noto Sans Lao', sans-serif",
                            }}
                          >
                            TK: {item?.trackingId}
                          </strong>
                        </div>
                        <div className="col-12">
                          <strong
                            style={{
                              fontWeight: "bold",
                              color: "grey",
                              fontFamily: "'Noto Sans Lao', sans-serif",
                            }}
                          >
                            ID: {item?.customer?.id_list}
                          </strong>
                        </div>
                        <div className="col-12">
                          <strong
                            style={{
                              fontWeight: "bold",
                              color: "grey",
                              fontFamily: "'Noto Sans Lao', sans-serif",
                            }}
                          >
                            ຊື່: {item?.customer?.full_name}
                          </strong>
                        </div>
                        <div className="col-12">
                          <strong
                            style={{
                              fontWeight: "bold",
                              color: "grey",
                              fontFamily: "'Noto Sans Lao', sans-serif",
                            }}
                          >
                            ຜູ້ຮັບ: {item?.receiverName}
                          </strong>
                        </div>
                        <div
                          className="col-6 text-nowrap"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          ຄ່າບໍລິການ: {currency(item?.realDeliveryPrice || 0)} ₭
                        </div>
                        <div className="col-12">
                          <a
                            className="text-link text-success"
                            target="_blank"
                            href={`https://wa.me/${detectPhoneNumber(
                              item?.receiverPhone
                            )}?text=${message?.replace(/<br\s*[\/]?>/gi, " ")}`}
                          >
                            <img style={{ width: 20 }} src={whatsapp} alt="" />
                            <span
                              style={{
                                fontWeight: "bold",
                                fontFamily: "'Noto Sans Lao', sans-serif",
                              }}
                            >
                              {item?.receiverPhone}
                            </span>
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                ))}
                {_item?.map((item) => (
                  <Card
                    className="shadow mb-1"
                    key={item?._id}
                    onClick={() =>
                      history.push(`${DETAIL_ITEMS_COMPLETED}/${item?._id}`)
                    }
                  >
                    <Row className="p-1">
                      <Col className="col-3 d-flex flex-column align-items-center">
                        <img
                          src={PACKAGE_LOGO}
                          alt="PACKAGE_LOGO"
                          style={{ width: 70 }}
                        />
                        <h6 className="text-muted text-nowrap">
                          {formatDateDash(item?.deliveryCompletedDate)}
                        </h6>
                        {item?.itemStatus === "COMPLETED" ? (
                          <small className="text-success">
                            {ItemStatus(item?.itemStatus)}
                          </small>
                        ) : (
                          <small className="text-danger">
                            {ItemStatus(item?.itemStatus)}
                          </small>
                        )}
                      </Col>
                      <Col className="col-9">
                        <div className="col-12 d-flex">
                          <strong
                            style={{
                              fontWeight: "bold",
                              color: "grey",
                              fontFamily: "'Noto Sans Lao', sans-serif",
                            }}
                          >
                            TK: {item?.trackingId}
                          </strong>
                        </div>
                        <div className="col-12">
                          <strong
                            style={{
                              fontWeight: "bold",
                              color: "grey",
                              fontFamily: "'Noto Sans Lao', sans-serif",
                            }}
                          >
                            ID: {item?.customer?.id_list}
                          </strong>
                        </div>
                        <div className="col-12">
                          <strong
                            style={{
                              fontWeight: "bold",
                              color: "grey",
                              fontFamily: "'Noto Sans Lao', sans-serif",
                            }}
                          >
                            ຊື່: {item?.customer?.full_name}
                          </strong>
                        </div>
                        <div className="col-12">
                          <strong
                            style={{
                              fontWeight: "bold",
                              color: "grey",
                              fontFamily: "'Noto Sans Lao', sans-serif",
                            }}
                          >
                            ຜູ້ຮັບ: {item?.receiverName}
                          </strong>
                        </div>
                        <div
                          className="col-6 text-nowrap"
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          ຄ່າບໍລິການ: {currency(item?.realDeliveryPrice || 0)} ₭
                        </div>
                        <div className="col-12">
                          <a
                            className="text-link text-success"
                            target="_blank"
                            href={`https://wa.me/${detectPhoneNumber(
                              item?.receiverPhone
                            )}?text=${message?.replace(/<br\s*[\/]?>/gi, " ")}`}
                          >
                            <img style={{ width: 20 }} src={whatsapp} alt="" />
                            <span
                              style={{
                                fontWeight: "bold",
                                fontFamily: "'Noto Sans Lao', sans-serif",
                              }}
                            >
                              {item?.receiverPhone}
                            </span>
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                ))}
                <br />
              </>
            ) : (
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ marginTop: "60px" }}
              >
                <img src={NODATA} style={{ width: "130px" }} alt="No Data" />
                <div style={{ fontWeight: "bold" }}>ບໍ່ມີຂໍ້ມູນ</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}
