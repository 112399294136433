import React, { useEffect, useState } from "react";
import Notiflix, { Loading } from "notiflix";
import {
  getStaffLogin,
  userStatus,
  loadingScreen,
  setGender,
  TOKEN,
  formatDateDash,
} from "../../helper";
import BottomNav from "../../layouts/BottomNav";
import { useLazyQuery } from "@apollo/client";
import male from "../../img/male.png";
import female from "../../img/female.png";
import { LOGIN } from "../../routes/app";
import { USERS } from "./apollo";

export default function Profile() {
  const [listData, setListData] = useState([]);
  const userState = getStaffLogin();

  const [fetchData, { data: dataStaff }] = useLazyQuery(USERS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          role: "SHIPPER",
          _id: parseInt(userState?._id),
        },
      },
    });
  }, []);
  useEffect(() => {
    setListData(dataStaff?.users?.data[0]);
  }, [dataStaff]);
  const onLogout = () => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      () => {
        loadingScreen();
        setTimeout(() => {
          Loading.remove();
          localStorage.clear();
          window.location.replace(LOGIN);
          localStorage.removeItem(TOKEN);
        }, 1000);
      },
      () => {
        return false;
      }
    );
  };

  return (
    <div>
      <div id="appCapsule">
        <div className="appHeader text-white border-0 ">
          <div style={{ flex: 1 }} className="text-left">
          </div>
          ໂປຣໄຟລ໌
          <div
            className="text-white pageTitle text-right text-nowrap pr-0"
            style={{ flex: 1 }}
          >
            <button
              className="btn text-white ml-0"
              onClick={() => onLogout()}
              style={{ flex: 1, marginRight: -10 }}
            >
              <i className="icon-log-out1" style={{ fontSize: 25 }} />
            </button>
          </div>
        </div>
        <br />
        <div className="body-content bg-white" style={{ marginTop: -30 }} >
          <center>
            {listData?.gender === "FEMALE" ? (
              <img
                src={listData?.profileImage ? listData?.profileImage : female}
                alt="logo"
                className="logo p-2"
                style={{
                  width: 120,
                  height: 120,
                  borderRadius: "40%",
                  border: "2px solid de0a0af2",
                }}
              />
            ) : (
              <img
                src={listData?.profileImage ? listData?.profileImage : male}
                alt="logo"
                className="logo p-2"
                style={{
                  width: 120,
                  height: 120,
                  borderRadius: "40%",
                  border: "2px solid de0a0af2",
                }}
              />
            )}
            <br />
            <b style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ID: {listData?.cvID}</b>
          </center>
          <div style={{ marginTop: -20 }}>
            <div className="session-list">
              <div className="listview-title ml-0 mb-2"></div>
              <div className="p-2">
                <div>
                  <div className="item">
                    <div className="mb-2 d-flex justify-content-between">
                      <div style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ຊື່ ແລະ ນາມສະກຸມ</div>
                      <div className="col-7 text-end custom-control custom-switch user-select-all" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {listData?.firstName ? listData?.firstName : "-"}{" "}
                        {listData?.lastName ? listData?.lastName : "-"}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="item">
                    <div className="mb-2 d-flex justify-content-between">
                      <div style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເພດ</div>
                      <div className="custom-control custom-switch user-select-all" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                        {setGender(listData?.gender ? listData?.gender : "-")}{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="item">
                    <div className="mb-2 d-flex justify-content-between">
                      <div style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ແຂວງ</div>
                      <div className="custom-control custom-switch" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                        {listData?.province?.title
                          ? listData?.province?.title
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="item">
                    <div className="mb-2 d-flex justify-content-between">
                      <div style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເມືອງ</div>
                      <div className="custom-control custom-switch user-select-all" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                        {listData?.district?.title
                          ? listData?.district?.title
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="item">
                    <div className="mb-2 d-flex justify-content-between">
                      <div style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ບ້ານ</div>
                      <div className="custom-control custom-switch" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                        {listData?.village?.title
                          ? listData?.village?.title
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="item">
                    <div className="mb-2 d-flex justify-content-between">
                      <div style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ວັນທີ່ເລີ່ມວຽກ</div>
                      <div className="custom-control custom-switch user-select-all" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                        {formatDateDash(
                          listData?.workStartDate
                            ? listData?.workStartDate
                            : "-"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="item">
                    <div className="mb-2 d-flex justify-content-between">
                      <div style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເບີໂທ</div>
                      <div className="custom-control custom-switch user-select-all" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                        {listData?.phoneNumber ? listData?.phoneNumber : "-"}
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="item">
                    <div className="mb-2 d-flex justify-content-between">
                      <div style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ບ່ອນປະຈຳການ</div>
                      <div className="custom-control custom-switch" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                        {listData?.branch?.title
                          ? listData?.branch?.title
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="item">
                    <div className="mb-2 d-flex justify-content-between">
                      <div style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ຕຳແຫນ່ງ</div>
                      <div className="custom-control custom-switch user-select-all" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                        {userStatus(listData?.role ? listData?.role : "-")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomNav />
      </div>
    </div>
  );
}
