import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { Card } from "react-bootstrap";
import { HOME_PAGE } from "../../../routes/app";
import ItemPickupReceive from "./ItemReceive";
import ItemCancel from "./ItemCancel";
import ItemCompleted from ".";

export default function TabMenuList() {
  const { history, location } = useReactRouter();
  const params = new URLSearchParams(location?.search);
  const [tabActive, setTabActive] = useState("completed");

  useEffect(() => {
    const tab = params.get("item");
    if (tab) {
      setTabActive(tab);
    }
  }, [params]);

  useEffect(() => {
    const elmTab = document.querySelectorAll(".nav-tabs-custom")[0];
    const elmItemTab = document.querySelectorAll("button.active")[0];
    if (elmTab && elmItemTab) {
      const left = elmItemTab.offsetLeft - 10;
      elmTab.scrollLeft = left;
    }
  }, [tabActive]);

  return (
    <>
      <div className="appHeader text-light border-0 mr-0">
        <div style={{ flex: 1 }} className="text-left">
          <button
            className="btn text-white"
            onClick={() => history.push(HOME_PAGE)}
          >
            <i className="fa fa-chevron-left fs-4" />
          </button>
        </div>
        <b className="text-white">ອໍເດີທັງໝົດ</b>
        <div
          className="text-white pageTitle text-right text-nowrap pr-0"
          style={{ flex: 1 }}
        >
        </div>
      </div>
      <div className="d-flex mr-1 ms-2" style={{ marginTop: 60 }}>
        <Card
          className="item-tab btn-md text-center text-nowrap me-1 p-1 w-100"
          variant=""
          style={{ backgroundColor: tabActive === 'completed' ? '#ac2614' : '#687787' }}
          onClick={() => history.push({ search: "?item=completed" })}
        >
          <div className="text-white">
            ອໍເດີສົ່ງສຳເລັດ
          </div>
        </Card>
        <Card
          className="item-tab btn-md text-center text-nowrap me-1 p-1 w-100"
          variant=""
          style={{ backgroundColor: tabActive === 'receive' ? '#ac2614' : '#687787' }}
          onClick={() => history.push({ search: "?item=receive" })}
        >
          <div className="text-white">
            ອໍເດີຮັບເຂົ້າ
          </div>
        </Card>
        <Card
          className="item-tab btn-md text-center text-nowrap p-1 w-100"
          variant=""
          style={{ backgroundColor: tabActive === 'cancel' ? '#ac2614' : '#687787' }}
          onClick={() => history.push({ search: "?item=cancel" })}
        >
          <div className="text-white">
            ອໍເດີລົ້ມເຫຼວ
          </div>
        </Card>
      </div>
      <div style={{ marginTop: -40 }}>
        {tabActive === "receive" && <ItemPickupReceive />}
        {tabActive === "completed" && <ItemCompleted />}
        {tabActive === "cancel" && <ItemCancel />}
      </div>
    </> 
  );
}
