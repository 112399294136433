import React, { useEffect, useState } from "react";
import {
  detectPhoneNumber,
  formatDateDash,
  getStaffLogin,
  ShipperStatus,
  toDayDash,
} from "../../../helper";
import NODATA from "../../../img/Nodata.png";
import BottomNav from "../../../layouts/BottomNav";
import { QUERY_LIST_ITEM } from "../apollo";
import { useLazyQuery } from "@apollo/client";
import { Card, Col } from "react-bootstrap";
import Package_cancel from "../../../img/wrong-parcel.png";

export default function ItemMiddles() {
  const userState = getStaffLogin();
  const [_item, setResult] = useState();
  const [searchValue, setSearchValue] = useState();
  const [eventSearch, setEventSearch] = useState();
  const [startDateValue, setStartDateValue] = useState(toDayDash());
  const [endDateValue, setEndDateValue] = useState(new Date());
  const [fetchData, { data: result }] = useLazyQuery(QUERY_LIST_ITEM, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          customer: searchValue ? parseInt(searchValue) : undefined,
          shipper: userState?._id,
          status: "CANCELED",
          canceledDateBetween: [startDateValue, endDateValue],
        },
        orderBy: "DESC",
        limit: 0,
      },
    });

  }, [startDateValue, eventSearch, endDateValue]);


  useEffect(() => {
    if (result) {
      setResult(result?.pickupOfItems?.data);
    }
  }, [result]);

  const total = result?.pickupOfItems?.total;
  const message = "ສະບາຍດີ";


  return (
    <>
      <div className=" body-content-lg">
        <div className="option-section">
          <div className="col-12">
            <div className="listed-detail">
              <div className="row">
                <div className="col-6 mb-1">
                  <input
                    type="date"
                    className="form-control form-control-md"
                    value={formatDateDash(startDateValue)}
                    onChange={(e) => {
                      setStartDateValue(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6 mb-1">
                  <input
                    type="date"
                    className="form-control form-control-md"
                    value={formatDateDash(endDateValue)}
                    onChange={(e) => {
                      setEndDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control form-control-md"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  value={searchValue}
                  placeholder="ຄົ້ນຫາ ID ລູກຄ້າ"
                />
                <button
                  type="button"
                  className="btn-dark"
                  style={{ width: "70px", borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem" }}
                  onClick={(e) => setEventSearch(!eventSearch)}
                >
                  <i className="icon-search1" />
                </button>
              </div>
            </div>
            <div className="title mt-1" style={{ fontWeight: "bold" }}>ຈຳນວນ {total || 0} ລາຍການ</div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="section">
          <div className="transactions ">
            {_item && _item.length > 0 ? (
              _item.map((item, index) => (
                <Card className="shadow mb-1" key={index} style={{ backgroundColor: "#FFF8F8" }}>
                  <div className="d-flex align-items-center justify-content-center">
                    <Col className="col-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <img src={Package_cancel} alt="LOGO_PACKAGE_CANCEL" style={{ width: 70 }} />
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <h6 className="text-muted text-nowrap">ວັນທີ່: {item?.canceledDate ? formatDateDash(item.canceledDate) : "--"}</h6>
                      </div>
                    </Col>
                    <Col className="col-8">
                      <div className="d-flex">
                        <strong className="col-7"><span style={{ fontWeight: "bold", fontFamily: "'Noto Sans Lao', sans-serif" }}>ID: {item?.customer?.id_list}</span></strong>
                        <div className="col-5">ຈຳນວນ: {item?.amount}</div>
                      </div>
                      <div className="d-flex ">
                        <div className="col-7">
                          <a
                            className="text-link text-success"
                            target="_blank"
                            href={`https://wa.me/${detectPhoneNumber(
                              item?.customer?.contact_info
                            )}?text=${message?.replace(/<br\s*[\/]?>/gi, " ")}`}
                          >
                            <i className="fas fa-phone" /> <span style={{ fontWeight: "bold", fontFamily: "'Noto Sans Lao', sans-serif" }}>{item?.customer?.contact_info}</span>
                          </a>
                        </div>
                        <div className="col-5">
                          <small>{ShipperStatus(item?.status)}</small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div>ຊື່: {item?.customer?.full_name}</div>
                      </div>
                    </Col>
                  </div>
                </Card>
              ))) : (
              <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "100px" }}>
                <img src={NODATA} style={{ width: "130px" }} />
                <div style={{ fontWeight: 'bold' }}>
                  ບໍ່ມີຂໍ້ມູນ
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}
