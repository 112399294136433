import React, { useEffect, useState } from "react";
import {
  detectPhoneNumber,
  formatDateDash,
  getStaffLogin,
  ShipperStatus,
  startMonth,
} from "../../../helper";
import BottomNav from "../../../layouts/BottomNav";
import { useLazyQuery } from "@apollo/client";
import { QUERY_LIST_ITEM } from "../apollo";
import image from "../../../img/wrong.png";
import { Card, Col, Row } from "react-bootstrap";
import Package from "../../../img/package.png";
import Nodata from "../../../img/Nodata.png";

export default function ItemPickupReceive() {
  const userState = getStaffLogin();
  const [startDateValue, setStartDateValue] = useState(startMonth());
  const [endDateValue, setEndDateValue] = useState(new Date());
  const [searchValue, setSearchValue] = useState();
  const [eventSearch, setEventSearch] = useState();
  const [_item, setResult] = useState();

  const [fetchData, { data: result }] = useLazyQuery(QUERY_LIST_ITEM, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          status: "RECEIVEDs",
          customer: searchValue ? parseInt(searchValue) : undefined,
          shipper: userState?._id,
          receivedDateBetween: [startDateValue, endDateValue],
        },
        orderBy: "DESC",
        limit: 0,
      },
    });
  }, [eventSearch, startDateValue, endDateValue]);

  useEffect(() => {
    setResult(result?.pickupOfItems?.data);
  }, [result]);

  const total = result?.pickupOfItems?.total;
  const message = "ສະບາຍດີ";

  return (
    <>
      <div className=" body-content-lg" style={{ marginTop: 50 }}>
        <div className="option-section">
          <div className="col-12">
            <div className="listed-detail">
              <div className="row">
                <div className="col-6 mb-1">
                  <input
                    type="date"
                    className="form-control form-control-md"
                    value={formatDateDash(startDateValue)}
                    onChange={(e) => {
                      setStartDateValue(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6 mb-1">
                  <input
                    type="date"
                    className="form-control form-control-md"
                    value={formatDateDash(endDateValue)}
                    onChange={(e) => {
                      setEndDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  value={searchValue}
                  placeholder="ຄົ້ນຫາ ID ລູກຄ້າ"
                />
                <button
                  type="button"
                  className="btn-dark"
                  style={{ width: "70px", borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem" }}
                  onClick={(e) => setEventSearch(!eventSearch)}
                >
                  <i className="icon-search1" />
                </button>
              </div>
            </div>
            <p className="title mt-1">ຈຳນວນ {total || 0} ລາຍການ</p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="section">
          <div className="transactions ">
            {_item && _item.length > 0 ? (
              _item.map((item) => (
                <Card className="shadow mb-1" key={item?._id} style={{ backgroundColor: "#F5FDF2" }}>
                  <Row>
                    <Col className="col-3 p-1 align-items-center justify-content-center">
                      <img src={Package} className="img-xl mt-2 ms-2" alt="Package" />
                      <div className="text-nowrap ms-1">{formatDateDash(item?.receivedDate || " ")}</div>
                    </Col>
                    <Col className="col-5 p-1">
                      <strong>ຈາກID: {item?.customer?.id_list}</strong>
                      <div className="col-12 text-nowrap" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                        ຊື່: {item?.customer?.full_name}
                      </div>
                      <div>
                        <a
                          className="text-link text-success"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://wa.me/${detectPhoneNumber(item?.customer?.contact_info)}?text=${message?.replace(/<br\s*[\/]?>/gi, " ")}`}
                        >
                          <i className="fas fa-phone" /> {item?.customer?.contact_info}
                        </a>
                      </div>
                      <div>ຈຳນວນ: {item?.amount}</div>
                      <small className="text-success">
                        {ShipperStatus(item?.status)}
                      </small>
                    </Col>
                    <Col className="col-4 p-1 d-flex flex-column justify-content-center align-items-center">
                      <h3>ລາຍເຊັນລູກຄ້າ</h3>
                      {item?.signature?.length > 0 ? (
                        <img
                          className="img-xl rounded-circle"
                          src={item?.signature[item?.signature?.length - 1]?.image}
                          alt="Signature"
                          style={{
                            width: 100,
                            height: 100,
                            borderRadius: "40%",
                            border: "2px solid de0a0af2",
                          }}
                        />
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <img src={image} alt="No data" style={{ width: "70px", height: "70px" }} />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card>
              ))
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <img src={Nodata} alt="No data" style={{ width: "30%", marginTop: "40%" }} />
                </div>
                <div className="text-center">
                  ບໍ່ມີຂໍ້ມູນ
                </div>
              </>
            )}
            <br />
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}
