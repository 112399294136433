import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import {
  detectPhoneNumber,
  messageError,
  messageSuccess,
  getStaffLogin,
  formatDateDash,
  currency,
} from "../../../helper";
import { HOME_PAGE } from "../../../routes/app";
import BottomNav from "../../../layouts/BottomNav";
import Notiflix from "notiflix";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Card, Col, Row } from "react-bootstrap";
import ANOUSITH_LOGO from "../../../img/logo-anousith.jpeg";
import NODATA from "../../../img/Nodata.png";
import CODCompleted from "../../items/codCompleted";
import WHATSAPP from "../../../img/whatsapp.svg";
import { LIST_SHIPPER_ITEM, UPDATE_ITEMS } from "../apollo";
import SentBackShop from "../../settings/widget/sentBackShop";

export default function ItemDelivering() {
  const { history } = useReactRouter();
  const [reloadData, setReloadData] = useState(false);
  const [item_Shipper_Branch, setItem_Shipper_Branch] = useState();
  const userState = getStaffLogin();
  const [searchValue, setSearchValue] = useState();
  const [eventSearch, setEventSearch] = useState();
  const [_sentStatus, setSentStatus] = useState(null);
  const [updateItems] = useMutation(UPDATE_ITEMS);
  const [onReload, setOnReload] = useState();

  const [fetchData_Shipper, { data: result_Shipper_Branch }] = useLazyQuery(
    LIST_SHIPPER_ITEM,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    fetchData_Shipper({
      variables: {
        where: {
          shipper: userState?._id,
          itemStatusIn: ["ASSIGNED_SHIPPER"],
          trackingId: searchValue ? searchValue : undefined,
        },
        orderBy: "DESC",
        limit: searchValue ? 0 : 0,
      },
    });
  }, [reloadData, result_Shipper_Branch, eventSearch, onReload]);

  useEffect(() => {
    if (result_Shipper_Branch) {
      setItem_Shipper_Branch(result_Shipper_Branch?.items?.data);
    }
  }, [result_Shipper_Branch]);

  //ຈຳນວນທັງໝົດ
  const total_Shipper_order_branch = result_Shipper_Branch?.items?.total;

  const _updateItems_Branch = (item) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ອໍເດີຂອງທ່ານລົ້ມເຫຼວ ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _updateResult = await updateItems({
            variables: {
              data: {
                sentStatus: _sentStatus?.value,
                shipper: parseInt(userState?._id),
                customer: parseInt(item?.customer?.id_list),
              },
              where: {
                _id: parseInt(item?._id),
              },
            },
          });

          if (_updateResult) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setReloadData(!reloadData);
          }
        } catch (error) {
          console.log(error);
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="appHeader text-light border-0 mr-0">
        <div style={{ flex: 1 }} className="text-left">
          <button
            className="btn text-white"
            onClick={() => history.push(HOME_PAGE)}
          >
            <i className="fa fa-chevron-left fs-4" />
          </button>
        </div>
        <b className="text-white">ອໍເດີກຳລັງຈັດສົ່ງ</b>
        <div
          className="text-white pageTitle text-right text-nowrap pr-0"
          style={{ flex: 1 }}
        ></div>
      </div>
      <div style={{ marginTop: 70 }}>
        <div className="col-12">
          <div className="d-flex mb-1">
            <div className="input-group">
              <input
                type="search"
                className="form-control form-control-sm"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                value={searchValue}
                placeholder="ຄົ້ນຫາ Tracking ID"
              />
              <button
                type="button"
                className="btn-dark"
                style={{
                  width: "70px",
                  borderTopRightRadius: "0.25rem",
                  borderBottomRightRadius: "0.25rem",
                }}
                onClick={(e) => setEventSearch(!eventSearch)}
              >
                <i className="icon-search1" />
              </button>
            </div>
          </div>
          <div style={{ fontWeight: "bold" }}>
            ຈຳນວນ {total_Shipper_order_branch || 0} ລາຍການ
          </div>
        </div>
        <div className="section">
          <div className="transactions_item">
            {item_Shipper_Branch && item_Shipper_Branch.length > 0 ? (
              item_Shipper_Branch.map((item) => (
                <Card
                  className="shadow mb-1 p-1 mt-1"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <div className="col-12 text-center d-flex">
                      <Col className="col-3 align-items-start">
                        <img
                          src={ANOUSITH_LOGO}
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "100%",
                          }}
                          alt="logo"
                        />
                      </Col>
                      <Col className="col-9 text-start">
                        <strong>
                          <span
                            className="text-grey ms-4"
                            style={{ fontWeight: "bold" }}
                          >
                            ລາຍລະອຽດລູກຄ້າ
                          </span>
                        </strong>
                      </Col>
                    </div>
                    <Col className="col-12">
                      <div className="p-1">
                        <div className="row d-flex text-nowrap">
                          <div className="col-6" style={{ fontWeight: "bold" }}>
                            <span
                              className="text-grey"
                              style={{ fontWeight: "bold" }}
                            >
                              ລະຫັດ:
                            </span>{" "}
                            {item?.trackingId}
                          </div>
                          <div
                            className="col-6 text-end"
                            style={{ fontWeight: "bold" }}
                          >
                            <span
                              className="text-grey"
                              style={{ fontWeight: "bold" }}
                            >
                              ວັນທີ:
                            </span>{" "}
                            {formatDateDash(item?.originReceiveDate)}
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-7"
                            style={{
                              fontWeight: "bold",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <span className="text-grey">ຊື່ຜູ້ສົ່ງ:</span>{" "}
                            <span className="bold-nato-sans">
                              {item?.customer?.full_name}
                            </span>
                          </div>
                          <a
                            className="text-link text-success col-5 text-end"
                            target="_blank"
                            href={`https://wa.me/${detectPhoneNumber(
                              item?.customer?.contact_info
                            )}?text=${`ສະບາຍດີ[ຮ້ານ ${item?.customer?.full_name}]ນ້ອງແມ່ນພະນັກງານ ຈາກບໍລິສັດ ອານຸສິດ ຂົນສົ່ງດ່ວນ | Sameday  ຂໍສອບຖາມແນ່? ເຄື່ອງພັດສະດຸເລກທີ${item?.trackingId}ເບີລູກຄ້າ${item?.receiverPhone}ໃຫ້ຈັດສົ່ງບ້ານ${item?.receiverVillage?.title}`?.replace(
                              /<br\s*[\/]?>/gi,
                              " "
                            )}`}
                          >
                            <img
                              src={WHATSAPP}
                              style={{ width: 20 }}
                              alt="whatsapp"
                            />
                            <span className="bold-nato-sans">
                              {" "}
                              {item?.customer?.contact_info}
                            </span>
                          </a>
                          <div className="col-4 text-nowrap">
                            <span className="text-grey bold-nato-sans">
                              IDຜູ້ສົ່ງ: {item?.customer?.id_list}
                            </span>
                          </div>
                          <div className="col-8 text-end">
                            <span className="bold-nato-sans">
                              {item?.itemName}
                            </span>
                          </div>
                          <div
                            className="col-7"
                            style={{
                              fontWeight: "bold",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <span className="text-grey">ຊື່ຜູ້ຮັບ:</span>{" "}
                            <span className="bold-nato-sans">
                              {item?.receiverName}
                            </span>
                          </div>
                          <a
                            className="text-link text-success col-5 text-end"
                            target="_blank"
                            href={`https://wa.me/${detectPhoneNumber(
                              item?.receiverPhone
                            )}?text=${`ສະບາຍດີຈາກອານຸສິດ ຂົນສົ່ງດ່ວນ | Sameday ເດີ້ເຈົ້າມີເລກພັດສະດຸ ${item?.trackingId} [ຈາກຮ້ານ ${item?.customer?.full_name}] ໃຫ້ຈັດສົ່ງມື້ນີ້ລູກຄ້າສະດວກຮັບເຄື່ອງຢູ່ ບ້ານ${item?.receiverVillage?.title}ແມ່ນບໍ່ເຈົ້າ? ພັດສະດຸນີ້ຈະຖືກຈັດສົ່ງໃຫ້ທ່ານເຖິ່ງບ້ານລູກຄ້າ ຖ້າຫາກລູກຄ້າຕອບຊ້າເກີນ 1 ຊົວໂມງອໍເດີນີ້ແມ່ນຈະຖືກສົ່ງໃນມື້ຖັດໄປ.`?.replace(
                              /<br\s*[\/]?>/gi,
                              " "
                            )}`}
                          >
                            <img
                              src={WHATSAPP}
                              style={{ width: 20 }}
                              alt="whatsapp"
                            />
                            <span className="bold-nato-sans">
                              {" "}
                              {item?.receiverPhone}
                            </span>
                          </a>
                        </div>
                      </div>
                      <Card
                        className="shadow p-1"
                        style={{ fontWeight: "bold" }}
                      >
                        <span className="text-center">ຄ່າບໍລີການທັງໝົດ</span>
                        <hr className="m-1" />
                        <div className="text-end">
                          <Row>
                            <Col className="col-6 text-nowrap text-start">
                              <div>
                                ຄ່າຂົນສົ່ງ: {currency(item?.realDeliveryPrice)}{" "}
                                KIP
                              </div>
                              <div>
                                <span
                                  className={
                                    item?.chargeOnShop === 1
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  {item?.chargeOnShop === 1
                                    ? "ຈ່າຍຕົ້ນທາງ"
                                    : "ຈ່າຍປາຍທາງ"}
                                </span>
                              </div>
                            </Col>
                            <Col className="col-6 text-nowrap text-end">
                              <div>{currency(item?.itemValueKIP)} KIP</div>
                              <div>{currency(item?.itemValueTHB)} THB</div>
                              <div>{currency(item?.itemValueUSD)} USD</div>
                            </Col>
                            <Col className="col-12 text-center mb-1">
                              ລວມທັງໝົດ:{" "}
                              {currency(
                                item?.chargeOnShop === 0
                                  ? item?.realDeliveryPrice + item?.itemValueKIP
                                  : item?.itemValueKIP
                              )}{" "}
                              KIP
                            </Col>
                          </Row>
                        </div>
                        <h className="text-center" style={{ fontSize: "13px" }}>
                          ເລືອກສະຖານະການຕິດຕໍ່ກັບລູກຄ້າ
                          <hr className="m-1" />
                        </h>
                        <Row style={{ fontWeight: "bold" }}>
                          <Col className="d-flex justify-content-center">
                            <Row>
                              <Col className="col-10 text-start">
                                <i className="fas fa-phone text-success me-1" />
                                <small>ໂທບໍ່ຮັບສາຍ</small>
                              </Col>
                              <Col className="col-2 text-start">
                                <input
                                  type="radio"
                                  value="NOT_ANSWER_CALL"
                                  name="sentStatus"
                                  onClick={(e) =>
                                    setSentStatus({
                                      value: e.target.value,
                                      itemID: item?._id,
                                    })
                                  }
                                />
                              </Col>
                              <Col className="col-10  d-flex text-nowrap">
                                <i className="fas fa-phone text-success me-1" />
                                <small>ບໍ່ສາມາດຕິດຕໍ່ໄດ້</small>
                              </Col>
                              <Col className="col-2">
                                <input
                                  type="radio"
                                  value="CAN_NOT_CONTACT"
                                  name="sentStatus"
                                  onClick={(e) =>
                                    setSentStatus({
                                      value: e.target.value,
                                      itemID: item?._id,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-6 d-flex justify-content-center">
                            <Row>
                              <Col className="col-10 text-start d-flex text-nowrap">
                                <i class="fa-solid fa-exclamation text-danger me-1" />
                                <small>ບໍ່ສາມາດສົ່ງໄດ້</small>
                              </Col>
                              <Col className="col-2 text-start">
                                <input
                                  type="radio"
                                  value="CAN_NOT_SENT"
                                  name="sentStatus"
                                  onClick={(e) =>
                                    setSentStatus({
                                      value: e.target.value,
                                      itemID: item?._id,
                                    })
                                  }
                                />
                              </Col>
                              <Col className="col-10 text-start text-nowrap">
                                <i class="fa-solid fa-circle-xmark text-danger me-1" />
                                <small>ຍົກເລີກ</small>
                              </Col>
                              <Col className="col-2 text-start justify-content-center">
                                <input
                                  type="radio"
                                  value={null}
                                  name="sentStatus"
                                  onClick={(e) =>
                                    setSentStatus({
                                      value: e.target.value,
                                      itemID: null,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <div className="row mt-1">
                        <div className="col-6">
                          <CODCompleted
                            disabled={_sentStatus?.itemID === item?._id}
                            data={item}
                            loadData={reloadData}
                            getData={(data) => {
                              setReloadData(data);
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <button
                            disabled={_sentStatus?.itemID !== item?._id}
                            type="button"
                            className="btn-secondary btn-sm p-2 right text-nowrap w-100 d-flex align-items-center justify-content-center"
                            style={{ borderRadius: 10 }}
                            onClick={() => _updateItems_Branch(item)}
                          >
                            <i class="fa-solid fa-circle-exclamation me-1" />
                            ອັບເດດການສົ່ງ
                          </button>
                        </div>
                      </div>
                      <div>
                        <SentBackShop id={item?._id} onReload={setOnReload} />
                      </div>
                    </Col>
                  </Row>
                </Card>
              ))
            ) : (
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ marginTop: "100px" }}
              >
                <img
                  src={NODATA}
                  style={{ width: "130px", marginTop: "40px" }}
                />
                <div style={{ fontWeight: "bold" }}>ບໍ່ມີຂໍ້ມູນ</div>
              </div>
            )}
            <br />
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}
