import React, { useEffect, useState } from "react";
import { HOME_PAGE } from "../../../routes/app";
import useReactRouter from "use-react-router";
import { Card, Col, Row } from "react-bootstrap";
import { detectPhoneNumber, formatDateDash, getStaffLogin } from "../../../helper";
import { LIST_SHIPPER_ITEM } from "../../home/apollo";
import { useLazyQuery } from "@apollo/client";
import WHATSAPP from "../../../img/whatsapp.svg";
import NODATA from "../../../img/Nodata.png";
import BottomNav from "../../../layouts/BottomNav";

export default function HistoryItem() {
  const { history } = useReactRouter();
  const userState = getStaffLogin();
  const [item, setItem] = useState([]);
  const [total, setTotal] = useState(0);
  const [fetchData_Shipper, { data }] = useLazyQuery(LIST_SHIPPER_ITEM, { fetchPolicy: "cache-and-network", });
  useEffect(() => {
    fetchData_Shipper({
      variables: {
        where: {
          shipper: userState?._id,
          itemStatus: "WAITING_BACK_SHOP",
          backward: 0,
        },
      },
    });
  }, [])

  useEffect(() => {
    setItem(data?.items?.data)
    setTotal(data?.items?.total)
  }, [data])

  const message = "ສະບາຍດີ";

  return (
    <React.Fragment>
      <div className="appHeader text-light border-0 mr-0">
        <div style={{ flex: 1 }} className="text-left">
          <button
            className="btn text-white"
            onClick={() => history.push(HOME_PAGE)}
          >
            <i className="fa fa-chevron-left fs-4" />
          </button>
        </div>
        <b className="text-white">ອໍເດີຄົງຄ້າງສົ່ງກັບສາຂາ</b>
        <div
          className="text-white pageTitle text-right text-nowrap pr-0"
          style={{ flex: 1 }}
        >
        </div>
      </div>
      <div className="p-1" style={{ marginTop: 70 }}>
        <div style={{ fontWeight: "bold" }}>ຈຳນວນ: {total} ລາຍການ</div>
        {item && item?.length > 0 ? (
          item?.map((item) => (
            <Card className="shadow mb-1 p-1 mt-1" style={{ backgroundColor: "#FFF8F8" }}>
              <Row>
                <div className="col-12 text-center">
                  <strong>
                    <span style={{ fontWeight: "bold" }}>
                      ພັດສະດຸຄົງຄ້າງ
                    </span>
                  </strong>
                </div>
                <Col className="col-12">
                  <div className="p-1">
                    <div className="row d-flex text-nowrap">
                      <div className="col-6" style={{ fontWeight: "bold" }}>
                        <span
                          className="text-grey"
                          style={{ fontWeight: "bold" }}
                        >
                          ລະຫັດ:
                        </span>{" "}
                        {item?.trackingId}
                      </div>
                      <div className="col-6 text-end" style={{ fontWeight: "bold" }}>
                        <span
                          className="text-grey"
                          style={{ fontWeight: "bold" }}
                        >
                          ວັນທີ:
                        </span>{" "}
                        {formatDateDash(item?.originReceiveDate)}
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-7"
                        style={{
                          fontWeight: "bold",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <span className="text-grey">ຊື່:</span>{" "}
                        <span className="bold-nato-sans">
                          {item?.customer?.full_name}
                        </span>
                      </div>
                      <a
                        className="text-link text-success col-5"
                        target="_blank"
                        href={`https://wa.me/${detectPhoneNumber(
                          item?.customer?.contact_info
                        )}?text=${message?.replace(/<br\s*[\/]?>/gi, " ")}`}
                      >
                        <img
                          src={WHATSAPP}
                          style={{ width: 20 }}
                          alt="whatsapp"
                        />
                        <span className="bold-nato-sans">
                          {" "}
                          {item?.customer?.contact_info}
                        </span>
                      </a>
                      <div className="col-6">
                        <span className="text-grey bold-nato-sans">
                          ID:
                        </span>
                        <span className="bold-nato-sans">
                          {item?.customer?.id_list}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          ))
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ marginTop: "100px" }}
          >
            <img src={NODATA} style={{ width: "130px", marginTop: "40px" }} />
            <div style={{ fontWeight: "bold" }}>ບໍ່ມີຂໍ້ມູນ</div>
          </div>
        )}
        <br/>
      </div>
      <BottomNav />
    </React.Fragment>
  )
}

