import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import {
  detectPhoneNumber,
  formatDateDash,
  getStaffLogin,
  ShipperStatus,
  startMonth,
  StatusDelivery,
} from "../../../helper";
import BottomNav from "../../../layouts/BottomNav";
import { useLazyQuery } from "@apollo/client";
import { LIST_ITEM_DELIVERY, QUERY_LIST_ITEM } from "../apollo";
import { DETAIL_DATA_LIST } from "../../../routes/app";
import whatsapp from "../../../icon/whatsapp.svg";
import { Card, Col, Row } from "react-bootstrap";
import Package_cancel from "../../../img/wrong-parcel.png";
import NODATA from "../../../img/Nodata.png";

export default function ItemCancel() {
  const { history } = useReactRouter();
  const userState = getStaffLogin();
  const [startDateValue, setStartDateValue] = useState(startMonth());
  const [endDateValue, setEndDateValue] = useState(new Date());
  const [searchValue, setSearchValue] = useState();
  const [eventSearch, setEventSearch] = useState();
  const [_item, setResult] = useState();
  const [dataItem, setResultItem] = useState();

  const [fetchData, { data: result }] = useLazyQuery(QUERY_LIST_ITEM, {
    fetchPolicy: "cache-and-network",
  });

  const [fetchDeliVery, { data: resultDelivery }] = useLazyQuery(
    LIST_ITEM_DELIVERY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          customer: searchValue ? parseInt(searchValue) : undefined,
          shipper: userState?._id,
          canceledDateBetween: [startDateValue, endDateValue],
          status: "CANCELED",
        },
        orderBy: "DESC",
        limit: 0,
      },
    });
  }, [eventSearch]);

  useEffect(() => {
    fetchDeliVery({
      variables: {
        where: {
          shipper: userState?._id,
          dateBetween: [startDateValue, endDateValue],
        },
        orderBy: "DESC",
        limit: 0,
      },
    });
  }, [startDateValue, endDateValue, eventSearch]);

  useEffect(() => {
    setResult(result?.pickupOfItems?.data);
  }, [result]);

  useEffect(() => {
    const _results = resultDelivery?.itemDeliveryLogs?.data;
    if (searchValue) {
      const filter = _results?.filter((obj) => {
        if (obj?.item?.trackingId?.includes(searchValue)) {
          return obj;
        }
      });
      setResultItem(filter);
    } else {
      setResultItem(_results);
    }
  }, [resultDelivery, eventSearch]);

  const total = result?.pickupOfItems?.total || 0;
  const totalItem = resultDelivery?.itemDeliveryLogs?.total || 0;
  const message = "ສະບາຍດີ";

  return (
    <>
      <div className=" body-content-lg" style={{ marginTop: 50 }}>
        <div className="option-section">
          <div className="col-12">
            <div className="listed-detail">
              <div className="row">
                <div className="col-6 mb-1">
                  <input
                    type="date"
                    className="form-control form-control-md"
                    value={formatDateDash(startDateValue)}
                    onChange={(e) => {
                      setStartDateValue(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6 mb-1">
                  <input
                    type="date"
                    className="form-control form-control-md"
                    value={formatDateDash(endDateValue)}
                    onChange={(e) => {
                      setEndDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  value={searchValue}
                  placeholder="ຄົ້ນຫາ Tracking ID ເເລະ ID ລູກຄ້າ"
                />
                <button
                  type="button"
                  className="btn-dark"
                  style={{
                    width: "70px",
                    borderTopRightRadius: "0.25rem",
                    borderBottomRightRadius: "0.25rem",
                  }}
                  onClick={(e) => setEventSearch(!eventSearch)}
                >
                  <i className="icon-search1" />
                </button>
              </div>
            </div>
            <p className="title mt-1">ຈຳນວນ {total + totalItem} ລາຍການ</p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="section">
          <div className="transactions ">
            {(_item && _item?.length > 0) ||
            (dataItem && dataItem?.length > 0) ? (
              <>
                {_item &&
                  _item?.map((item) => (
                    <Card
                      className="shadow mb-1"
                      key={item?._id}
                      style={{ backgroundColor: "#FFE8E8" }}
                    >
                      <Row>
                        <Col className="col-4 d-flex flex-column align-items-center">
                          <img
                            src={Package_cancel}
                            alt="package_cancel"
                            style={{ width: "70px" }}
                          />
                          <h6 className="text-muted text-nowrap ms-2 bold-nato-sans">
                            ວັນທີ່: {formatDateDash(item?.canceledDate || " ")}
                          </h6>
                        </Col>
                        <Col className="col-8 p-1">
                          <div className="d-flex">
                            <strong className="col-6 text-nowrap bold-nato-sans">
                              ID: {item?.customer?.id_list}
                            </strong>
                            <div className="col-6 bold-nato-sans">
                              ຈຳນວນ: {item?.amount}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-6 text-nowrap">
                              <a
                                className="text-link text-success"
                                target="_blank"
                                href={`https://wa.me/${detectPhoneNumber(
                                  item?.customer?.contact_info
                                )}?text=${message?.replace(
                                  /<br\s*[\/]?>/gi,
                                  " "
                                )}`}
                              >
                                <i className="fas fa-phone" />
                                <span className="bold-nato-sans">
                                  {" "}
                                  {item?.customer?.contact_info}
                                </span>
                              </a>
                            </div>
                            <div className="col-6">
                              <small className="text-success bold-nato-sans">
                                {ShipperStatus(item?.status)}
                              </small>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-12 bold-nato-sans">
                              ຊື່: {item?.customer?.full_name}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  ))}

                {dataItem?.map((item) => (
                  <Card
                    className="shadow p-1 mb-1"
                    onClick={() =>
                      history.push(`${DETAIL_DATA_LIST}/${item?.item?._id} `)
                    }
                    style={{ backgroundColor: "#FFE8E8" }}
                  >
                    <Row>
                      <Col className="col-4 d-flex flex-column align-items-center">
                        <img
                          src={Package_cancel}
                          alt="package_cancel"
                          style={{ width: "70px" }}
                        />
                        <h6 className="text-muted text-nowrap bold-nato-sans">
                          ວັນທີ່: {formatDateDash(item?.createdDate || " ")}
                        </h6>
                      </Col>
                      <Col className="col-8">
                        <div className="bold-nato-sans">
                          TK: {item?.item?.trackingId}
                        </div>
                        <div className="bold-nato-sans">
                          ຊື່ເຄື່ອງ: {item?.item?.itemName}
                        </div>
                        <div>
                          <a
                            className="text-link text-success"
                            target="_blank"
                            href={`https://wa.me/${detectPhoneNumber(
                              item?.item?.receiverPhone
                            )}?text=${message?.replace(/<br\s*[\/]?>/gi, " ")}`}
                          >
                            <img style={{ width: 20 }} src={whatsapp} alt="" />{" "}
                            <span className="bold-nato-sans">
                              {" "}
                              {item?.item?.receiverPhone}
                            </span>
                          </a>
                        </div>
                        <small className="text-danger bold-nato-sans">
                          {StatusDelivery(item?.status)}
                        </small>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <img
                    src={NODATA}
                    alt="NODATA"
                    style={{ width: "30%", marginTop: "40%" }}
                  />
                </div>
                <div className="text-center">ບໍ່ມີຂໍ້ມູນ</div>
              </>
            )}
            <br />
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}
