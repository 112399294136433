import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import {
  detectPhoneNumber,
  formatDateDash,
  ItemStatus,
  startMonth,
  getStaffLogin,
  currency,
} from "../../../helper";
import BottomNav from "../../../layouts/BottomNav";
import { useLazyQuery } from "@apollo/client";
import { LIST_SHIPPER_ITEM } from "../apollo";
import { DETAIL_DATA_LIST } from "../../../routes/app";
import _ from "lodash";
import SumCommission from "../itemComplete/SumCommission";
import { Card, Col, Row } from "react-bootstrap";
import Package from "../../../img/package.png";
import NODATA from "../../../img/Nodata.png";
import WHATSAPP from "../../../img/whatsapp.svg";

export default function ItemCompleted() {
  const { history } = useReactRouter();
  const [startDateValue, setStartDateValue] = useState(startMonth());
  const [endDateValue, setEndDateValue] = useState(new Date());
  const [searchValue, setSearchValue] = useState();
  const [eventSearch, setEventSearch] = useState();
  const [_item, setResult] = useState();
  const userState = getStaffLogin();
  const [limitData, setLimitData] = useState(0);

  const [fetchData, { data: result }] = useLazyQuery(LIST_SHIPPER_ITEM, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          shipper: userState?._id,
          trackingId: searchValue ? searchValue : undefined,
          deliveryCompletedDateBetween: [startDateValue, endDateValue],
          itemStatus: "COMPLETED",
        },
        orderBy: "DESC",
        limit: Number(limitData),
      },
    });
  }, [eventSearch, startDateValue, endDateValue, limitData]);

  useEffect(() => {
    setResult(result?.items?.data);
  }, [result]);

  const total = result?.items?.total;
  const message = "ສະບາຍດີ";

  const _itemValueKIP = _.sumBy(_item, "itemValueKIP");
  const _itemValueTHB = _.sumBy(_item, "itemValueTHB");
  const _itemValueUSD = _.sumBy(_item, "itemValueUSD");
  const _deliveryPrice = _.sumBy(_item, "realDeliveryPrice");

  const handleLimitChange = (event) => {
    setLimitData(event.target.value);
  };

  return (
    <>
      <div className=" body-content-lg" style={{ marginTop: 50 }}>
        <div className="option-section">
          <div className="col-12">
            <div style={{ color: "black" }}>
              <Card className="p-1 mt-2" style={{ boxShadow: '0px 3px 5px 3px rgba(0, 0, 0, 0.15)' }} >
                <h3 className="text-center bold-nato-sans">ລາຍງານລວມ</h3>
                <div className="bold-nato-sans" style={{ color: "grey" }}>ເງິນເກັບໄດ້ຈິງ: {currency(_itemValueKIP || 0)} ₭</div>
                <div className="bold-nato-sans" style={{ color: "grey" }}>ເງິນເກັບໄດ້ຈິງ: {currency(_itemValueTHB || 0)} ฿</div>
                <div className="bold-nato-sans" style={{ color: "grey" }}>ເງິນເກັບໄດ້ຈິງ: {currency(_itemValueUSD || 0)} $</div>
                <div className="bold-nato-sans" style={{ color: "grey" }}>ຄ່າບໍລິການ: {currency(_deliveryPrice || 0)}</div>
              </Card>
              <Card className="p-1 mt-2" style={{ boxShadow: '0px 3px 5px 3px rgba(0, 0, 0, 0.15)' }}>
                <SumCommission startDate={startDateValue} endDate={endDateValue} />
              </Card>
            </div>
            <hr />
            <div className="listed-detail mt-1">
              <div className="row">
                <div className="col-6 mb-1">
                  <input
                    type="date"
                    className="form-control form-control-md"
                    value={formatDateDash(startDateValue)}
                    onChange={(e) => {
                      setStartDateValue(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6 mb-1">
                  <input
                    type="date"
                    className="form-control form-control-md"
                    value={formatDateDash(endDateValue)}
                    onChange={(e) => {
                      setEndDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  value={searchValue}
                  placeholder="ຄົ້ນຫາ Tracking ID"
                />
                <button
                  type="button"
                  className="btn-dark"
                  style={{ width: "70px", borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem" }}
                  onClick={(e) => setEventSearch(!eventSearch)}
                >
                  <i className="icon-search1" />
                </button>
              </div>
            </div>
            <Row className="mt-1">
              <Col>
                <p className="title mt-1">ຈຳນວນ {total || 0} ລາຍການ</p>
              </Col>
              <Col>
                <select className="form-select" onChange={handleLimitChange}>
                  <option value="">ຄ່າເລີ່ມຕົ້ນ</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                </select>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="section">
          <div className="transactions ">
            {_item && _item.length > 0 ? (
              _item?.map((item, index) => (
                <Card className="shadow mb-1" onClick={() =>
                  history.push(`${DETAIL_DATA_LIST}/${item?._id} `)
                }>
                  <Row>
                    <Col className="col-4 d-flex flex-column justify-content-center align-items-center">
                      <div
                        className="justify-content-center bold-nato-sans align-items-center text-nowrap"
                        style={{
                          borderRadius: "50%",
                          position: "absolute",
                          top: 8,
                          right: -205,
                        }}>
                        {index + 1}
                      </div>
                      <img src={Package} alt="LOGO_PACKAGE" style={{ width: 60 }} />
                      <div style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{formatDateDash(item?.createdDate)}</div>
                      <>
                        {item?.itemStatus === "COMPLETED" ? (
                          <small className="text-success" style={{ fontWeight: "bold" }}>
                            {ItemStatus(item?.itemStatus)}
                          </small>
                        ) : (
                          <small className="text-danger" style={{ fontWeight: "bold" }}>
                            {ItemStatus(item?.itemStatus)}
                          </small>
                        )}
                      </>
                    </Col>
                    <Col className="col-8 p-1">
                      <div>
                        <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>TK: {item?.trackingId}</strong>
                      </div>
                      <div>
                        <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ID: {item?.customer?.id_list}</strong>
                      </div>
                      <div
                        style={{
                          fontWeight: "bold",
                          color: "grey",
                          fontFamily: "'Noto Sans Lao', sans-serif",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}>
                        ຊື່: {item?.customer?.full_name}
                      </div>
                      <div>
                        <a
                          className="text-link text-success"
                          target="_blank"
                          href={`https://wa.me/${detectPhoneNumber(
                            item?.receiverPhone
                          )}?text=${message?.replace(/<br\s*[\/]?>/gi, " ")}`}
                        >
                          <img
                            src={WHATSAPP}
                            style={{ width: 20 }}
                            alt="whatsapp"
                          /> <span style={{ fontWeight: "bold", fontFamily: "'Noto Sans Lao', sans-serif" }}>{item?.receiverPhone}</span>
                        </a>
                      </div>
                      <div style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>ຜູ້ຮັບ: {item?.receiverName}</div>
                    </Col>
                  </Row>
                </Card>
              ))
            ) : (
              <>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={NODATA} alt="NODATA" style={{ width: "30%", marginTop: "10%" }} />
                </div>
                <div className="text-center">
                  ບໍ່ມີຂໍ້ມູນ
                </div>
              </>
            )}
            <br />
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}
