import React, { useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';

const TrackingBarcode = ({ trackingNumber }) => {
  const barcodeRef = useRef(null);

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, trackingNumber, {
        format: 'CODE128',
        lineColor: '#000',
        width: 2,
        height: 50,
        displayValue: true,
      });
    }
  }, [trackingNumber]);

  return (
    <div>
      <svg ref={barcodeRef} />
    </div>
  );
};

export default TrackingBarcode;