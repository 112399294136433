import React, { useEffect, useState } from "react";
import {
  detectPhoneNumber,
  formatDateDash,
  getStaffLogin,
  ShipperStatus,
  toDayDash,
} from "../../../helper";
import BottomNav from "../../../layouts/BottomNav";
import image from "../../../img/wrong.png";
import { useLazyQuery } from "@apollo/client";
import { QUERY_LIST_ITEM } from "../apollo";
import { Card, Col, Row } from "react-bootstrap";
import Package from "../../../img/package.png";
import WHATSAPP from "../../../img/whatsapp.svg";
import NODATA from "../../../img/Nodata.png";

export default function ItemIn() {
  const [_item, setResult] = useState();
  const [searchValue, setSearchValue] = useState();
  const [eventSearch, setEventSearch] = useState();
  const userState = getStaffLogin();
  const [startDateValue, setStartDateValue] = useState(toDayDash());
  const [endDateValue, setEndDateValue] = useState(toDayDash());

  const [fetchData, { data: result }] = useLazyQuery(QUERY_LIST_ITEM, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          status: "RECEIVED",
          customer: searchValue ? parseInt(searchValue) : undefined,
          shipper: userState?._id,
          receivedDateBetween: [startDateValue, endDateValue],
        },
        orderBy: "DESC",
        limit: 0,
      },
    });
  }, [eventSearch, startDateValue, endDateValue]);

  useEffect(() => {
    if (result) {
      setResult(result?.pickupOfItems?.data);
    }
  }, [result]);

  const total = result?.pickupOfItems?.total;
  const message = "ສະບາຍດີ";

  return (
    <>
      <div className=" body-content-lg">
        <div className="option-section">
          <div className="col-12">
            <div className="listed-detail mt-1">
              <div className="row">
                <div className="col-6 ">
                  <input
                    type="date"
                    className="form-control form-control-md w-100"
                    value={formatDateDash(startDateValue)}
                    onChange={(e) => {
                      setStartDateValue(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6 mb-1">
                  <input
                    type="date"
                    className="form-control form-control-md w-100"
                    value={formatDateDash(endDateValue)}
                    onChange={(e) => {
                      setEndDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="input-group">
              <input
                type="search"
                className="form-control form-control-md"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                value={searchValue}
                placeholder="ຄົ້ນຫາ ID ລູກຄ້າ"
              />
              <button
                type="button"
                className="btn-dark"
                style={{ width: "70px", borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem" }}
                onClick={(e) => setEventSearch(!eventSearch)}
              >
                <i className="icon-search1 text-white" />
              </button>
            </div>
            <div className="mt-1" style={{ fontWeight: "bold" }}>ຈຳນວນ {total || 0} ລາຍການ</div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="section">
          <div className="transactions ">
            {_item && _item.length > 0 ? (
              _item.map((item, index) => (
                <Card className="shadow mb-1" key={index} style={{ backgroundColor: "#F5FDF2" }}>
                  <Row className="p-1">
                    <Col className="col-4 align-items-center justify-content-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <img src={Package} alt="LOGO_PACKAGE" style={{ width: 60 }} />
                      </div>
                      <h3 className="text-nowrap mt-1">ລາຍເຊັນລູກຄ້າ</h3>
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          className="img-xl rounded-circle"
                          src={
                            item?.signature?.length
                              ? item?.signature[item?.signature?.length - 1]?.image
                              : image
                          }
                          style={{
                            width: "50%",
                            height: "100%",
                            borderRadius: "40%",
                            border: "2px solid de0a0af2",
                          }}
                        />
                      </div>
                    </Col>
                    <Col className="col-8">
                      <strong style={{ fontWeight: "bold", fontFamily: "'Noto Sans Lao', sans-serif" }}>ID: {item?.customer?.id_list}</strong>
                      <div style={{ fontWeight: "bold", fontFamily: "'Noto Sans Lao', sans-serif", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>ຊື່: {item?.customer?.full_name}</div>
                      <div style={{ fontWeight: "bold", fontFamily: "'Noto Sans Lao', sans-serif" }}>ຈຳນວນ: {item?.amount}</div>
                      <div style={{ fontWeight: "bold", fontFamily: "'Noto Sans Lao', sans-serif" }}>ວັນທີ່: {formatDateDash(item?.receivedDate || " ")}</div>
                      <div>
                        <a
                          className="text-link text-success"
                          target="_blank"
                          href={`https://wa.me/${detectPhoneNumber(
                            item?.customer?.contact_info
                          )}?text=${message?.replace(/<br\s*[\/]?>/gi, " ")}`}
                        >
                          <img src={WHATSAPP} alt="Whatsapp" style={{ width: 20 }} /><span style={{ fontWeight: "bold", fontFamily: "'Noto Sans Lao', sans-serif" }}> {item?.customer?.contact_info}</span>
                        </a>
                      </div>
                      <>
                        <small className="text-success" style={{ fontWeight: "bold", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                          {ShipperStatus(item?.status)}
                        </small>
                      </>
                    </Col>
                  </Row>
                </Card>
              ))
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "100px" }}>
                <img src={NODATA} style={{ width: "130px" }} />
                <div style={{ fontWeight: 'bold' }}>
                  ບໍ່ມີຂໍ້ມູນ
                </div>
              </div>
            )}
          </div>
        </div>
      </div >
      <BottomNav />
    </>
  );
}
