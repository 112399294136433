import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { HISTORY_COMMISSION } from "../../routes/app";
import BottomNav from "../../layouts/BottomNav";
import {
  endOfMonth,
  getStaffLogin,
  startMonth,
  currency,
  messageSuccess,
  messageError,
} from "../../helper";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  QUERY_COMMISSION,
  QUERY_PAYROLL_SUMMARY,
  UPDATE_PAYROLL,
} from "../home/apollo";
import Notiflix from "notiflix";
import DetailIBonusMoney from "./DetailAll/DetailBonus";
import DetailExtraIncome from "./DetailAll/DetailExtraIncome";
import DetailIBorrow from "./DetailAll/DetailBorow";
import DetailOT from "./DetailAll/DetailOT";
import DetailDeduct from "./DetailAll/DetailDeduct";
import DetailDiligent from "./DetailAll/DetailDiligent";
import DETAIL from "../../img/seo.png";

export default function DetailHistory() {
  const useInfo = getStaffLogin();
  const { history, match } = useReactRouter();
  const ID = parseInt(match?.params?._id);
  const [dataUser, setResultPayroll] = useState();
  const [reloadData, setReloadData] = useState(false);
  const [dataSummary, setResultSummary] = useState();
  const [startDate, setStartDate] = useState(startMonth());
  const [endDate, setEndDate] = useState(endOfMonth());

  const [detailIBonus, setDetailBonus] = useState();
  const [detailExtraIncome, setExtraIncome] = useState();
  const [detailBorrow, setDetailBorrow] = useState();
  const [detailOT, setDetailOT] = useState();
  const [detailDeduct, setDetailDeduct] = useState();
  const [detailDiligent, setDetailDiligent] = useState();

  const [updatePayroll] = useMutation(UPDATE_PAYROLL);
  const [fetchPayroll, { data: resultPayroll }] = useLazyQuery(
    QUERY_PAYROLL_SUMMARY,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [fetchSummary, { data: resultSummary }] = useLazyQuery(
    QUERY_COMMISSION,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    fetchPayroll({
      variables: {
        where: {
          _id: parseInt(ID),
        },
        limit: 1,
      },
    });

    fetchSummary({
      variables: {
        where: {
          shipper: parseInt(useInfo?._id),
          dateBetween: [startDate, endDate],
        },
      },
    });
  }, [startDate, endDate, reloadData]);

  useEffect(() => {
    setResultPayroll(resultPayroll?.summaryPayroll?.data);
  }, [resultPayroll]);

  useEffect(() => {
    setResultSummary(resultSummary?.summariesShippers);
  }, [resultSummary]);

  const _receive = parseInt(dataSummary?.receive?.total || 0);
  const _receiveCommission = parseInt(dataSummary?.receive?.commission || 0);

  let _sent = parseInt(dataSummary?.sent?.general?.total || 0);
  let _sentCommission = parseInt(dataSummary?.sent?.general?.commission || 0);

  let _sentNear = parseInt(dataSummary?.sent?.near?.total || 0);
  let _sentNearCommission = parseInt(dataSummary?.sent?.near?.commission || 0);

  let _sentFar = parseInt(dataSummary?.sent?.farAway?.total || 0);
  let _sentFarCommission = parseInt(
    dataSummary?.sent?.farAway?.commission || 0
  );

  let _total = parseInt(_sent + _sentNear + _sentFar);
  const amountCommission = parseInt(
    _sentCommission + _sentNearCommission + _sentFarCommission
  );

  const totalCommission = _total >= (200 || 240) ? 100000 : 0;
  const totalCommission1 = _total >= 250 ? 150000 : 0;

  const _updateConfirmStatus = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຢືນຢັນແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {

        try {
          const _updatePayroll = await updatePayroll({
            variables: {
              data: {
                confirmStatus: "CONFIRMED",
              },
              where: {
                _id: parseInt(id),
              },
            },
          });

          if (_updatePayroll) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setReloadData(!reloadData);
          }
        } catch (error) {
          console.log(error);
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="appHeader text-light border-0 mr-0">
        <div style={{ flex: 1 }} className="text-left">
          <button
            className="btn text-white"
            onClick={() => history.push(`${HISTORY_COMMISSION}/1`)}
          >
            <i className="fa fa-chevron-left fs-4" />
          </button>
        </div>
        <b className="text-white">ລາຍລະອຽດ</b>
        <div
          className="text-white pageTitle text-right text-nowrap pr-0"
          style={{ flex: 1 }}
        ></div>
      </div>
      <div className="container-min">
        <div className="col-12">
          <div style={{ paddingBottom: "20px" }} />
          <div className="listed-detail mt-5">
          </div>
          {dataUser &&
            dataUser?.map((item, index) => (
              <ul className="listview flush transparent simple-listview no-space mt-1 p-1">
                <div className="text-center mt-2">
                  <img src={DETAIL} alt="LOGO" style={{ width: "100px", height: "100px" }} />
                </div>
                <hr />
                <div className="mb-2 d-flex justify-content-between">
                  <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>CVID:</strong>
                  <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{item?.empID?.cvID}</span>
                </div>
                <div className="mb-2 d-flex justify-content-between">
                  <strong className="text-nowrap me-2" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ຊື່ ແລະ ນາມສະກຸນ:</strong>
                  <span className="text-end text-nowrap" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}> {item?.empID?.firstName}{" "}{item?.empID?.lastName}</span>
                </div>
                <div className="mb-2 d-flex justify-content-between">
                  <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນເດືອນພື້ນຖານ</strong>
                  <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.empID?.basicSalary || 0)} ₭</span>
                </div>
                <div className="mb-2 d-flex justify-content-between">
                  <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນຕຳແໜ່ງ</strong>
                  <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.positionSalary || 0)} ₭</span>
                </div>
                {item?.extraIncome !== 0 ? (
                  <div className="mb-2 d-flex justify-content-between" onClick={() => setExtraIncome(item?._id)}>
                    <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນເພິ່ມ</strong>
                    <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.extraIncome || 0)} ₭
                      <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                    </span>
                  </div>
                ) : null}

                {item?.otIncome !== 0 ? (
                  <div className="mb-2 d-flex justify-content-between" onClick={() => setDetailOT(item?._id)}>
                    <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນໂອທີ</strong>
                    <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.otIncome || 0)} ₭
                      <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                    </span>
                  </div>
                ) : null}

                {item?.bonusIncome !== 0 ? (
                  <div className="mb-2 d-flex justify-content-between" onClick={() => setDetailBonus(item?._id)}>
                    <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນໂບນັດ</strong>
                    <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.bonusIncome || 0)} ₭
                      <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                    </span>
                  </div>
                ) : null}

                {item?.diligentIncome !== 0 ? (
                  <div className="mb-2 d-flex justify-content-between" onClick={() => setDetailDiligent(item?._id)}>
                    <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນຂະຫຍັນ</strong>
                    <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.diligentIncome || 0)} ₭
                      <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                    </span>
                  </div>
                ) : null}

                {item?.borrowExpense !== 0 ? (
                  <div className="mb-2 d-flex justify-content-between" onClick={() => setDetailBorrow(item?._id)}>
                    <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນເບິກລ່ວງໜ້າ</strong>
                    <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.borrowExpense || 0)} ₭
                      <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                    </span>
                  </div>
                ) : null}

                {item?.allowance !== 0 ? (
                  <div className="mb-2 d-flex justify-content-between">
                    <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນອຸດໜຸນຄ່າຄອງຊີບ</strong>
                    <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.allowance || 0)} ₭
                    </span>
                  </div>
                ) : null}

                {item?.fuelMoney !== 0 ? (
                  <div className="mb-2 d-flex justify-content-between">
                    <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນຄ່ານ້ຳມັນ</strong>
                    <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.fuelMoney || 0)} ₭
                    </span>
                  </div>
                ) : null}

                {item?.deductionExpense !== 0 ? (
                  <div className="mb-2 d-flex justify-content-between" onClick={() => setDetailDeduct(item?._id)}>
                    <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນຫັກ</strong>
                    <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.deductionExpense || 0)} ₭
                      <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                    </span>
                  </div>
                ) : null}
                <div className="mb-2 d-flex justify-content-between">
                  <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງີນອາຫານ</strong>
                  <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                  {currency(item?.livingSalary || 0)} ₭
                  </span>
                </div>
                <div className="mb-2 d-flex justify-content-between">
                  <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ລາຍໄດ້ຈາກອໍເດີທັງໝົດ</strong>
                  <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                  {currency(item?.shipper_commission || 0)} ₭
                  </span>
                </div>
                {_total >= 250 ? (
                  <div className="mb-2 d-flex justify-content-between">
                    <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນເປົ້າ</strong>
                    <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}> {totalCommission1} ₭</span>
                  </div>
                ) : (
                  <div className="mb-2 d-flex justify-content-between">
                    <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງິນເປົ້າ</strong>
                    <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}> {totalCommission} ₭</span>
                  </div>
                )}
                <div className="mb-2 d-flex justify-content-between">
                  <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ຄ່າອາກອນ</strong>
                  <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.taxIncome || 0)} ₭</span>
                </div>
                <div className="mb-2 d-flex justify-content-between">
                  <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>ເງີນປະກັນສັງຄົມ</strong>
                  <span style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>{currency(item?.InsuranceExpense || 0)} ₭</span>
                </div>
                <div className="mb-2 d-flex justify-content-between">
                  <strong style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                    <i className="fa-solid fa-money-bill-wave mr-1" />
                    ເງິນເດືອນສຸດທິ
                  </strong>
                  <span className="text-success" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                    {currency(item?.finalIncome || 0)} ₭
                  </span>
                </div>
                <div className="mb-3 mt-3 d-flex justify-content-between" style={{ fontWeight: "bold", color: "grey", fontFamily: "'Noto Sans Lao', sans-serif" }}>
                  {item?.confirmStatus === "CONFIRMED" ? (
                    <button
                      className="btn btn-success btn-block btn-xs rounded-pill"
                      disabled
                      onClick={() => _updateConfirmStatus(item?._id)}
                    >
                      <i class="fa-regular fa-circle-check mr-1" />
                      ຢືັນຢັນເງິນເດືອນ
                    </button>
                  ) : (
                    <button
                      className="btn btn-success btn-block btn-md"
                      onClick={() => _updateConfirmStatus(item?._id)}
                    >
                      <i class="fa-regular fa-circle-check mr-1" />
                      ຢືັນຢັນເງິນເດືອນ
                    </button>
                  )}
                </div>
              </ul>
            ))}
        </div>
        <BottomNav />
      </div>
      <DetailIBonusMoney _id={detailIBonus} onHide={() => setDetailBonus()} />
      <DetailExtraIncome _id={detailExtraIncome} onHide={() => setExtraIncome()} />
      <DetailIBorrow _id={detailBorrow} onHide={() => setDetailBorrow()} />
      <DetailOT _id={detailOT} onHide={() => setDetailOT()} />
      <DetailDeduct _id={detailDeduct} onHide={() => setDetailDeduct()} />
      <DetailDiligent _id={detailDiligent} onHide={() => setDetailDiligent()} />
    </>
  );
}
