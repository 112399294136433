import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { Card } from "react-bootstrap";
import { HOME_PAGE } from "../../../routes/app";
import ItemCompleted from "./ItemCompleted";
import ItemCancel from "./ItemCancel";

export default function TabMenuCompleted() {
  const { history, location } = useReactRouter();
  const params = new URLSearchParams(location?.search);
  const [tabActive, setTabActive] = useState("completed");

  useEffect(() => {
    const tab = params.get("item");
    if (tab) {
      setTabActive(tab);
    }
  }, [params]);

  useEffect(() => {
    const elmTab = document.querySelectorAll(".nav-tabs-custom")[0];
    const elmItemTab = document.querySelectorAll("button.active")[0];
    if (elmTab && elmItemTab) {
      const left = elmItemTab.offsetLeft - 10;
      elmTab.scrollLeft = left;
    }
  }, [tabActive]);

  return (
    <>
      <div className="appHeader text-light border-0 mr-0">
        <div style={{ flex: 1 }} className="text-left">
          <button
            className="btn text-white"
            onClick={() => history.push(HOME_PAGE)}
          >
            <i className="fa fa-chevron-left fs-4" />
          </button>
        </div>
        <b className="text-white">ອໍເດີສົ່ງສຳເລັດ</b>
        <div
          className="text-white pageTitle text-right text-nowrap pr-0"
          style={{ flex: 1 }}
        ></div>
      </div>
      <div className="d-flex mr-1 ms-2" style={{ marginTop: 60 }}>
        <Card
          className="item-tab me-1 p-1 w-100 text-nowrap d-flex justify-content-center text-white"
          variant=""
          style={{ width: "160px", backgroundColor: tabActive === 'completed' ? '#ac2614' : '#687787' }}
          onClick={() => history.push({ search: "?item=completed" })}
        >
          <div className="text-center">
            ສົ່ງສຳເລັດ
          </div>
        </Card>
        <Card
          className="item-tab ms-1 p-1 w-100 text-nowrap d-flex justify-content-center text-white"
          variant=""
          style={{ width: "160px", backgroundColor: tabActive === 'itemCancel' ? '#ac2614' : '#687787' }}
          onClick={() => history.push({ search: "?item=itemCancel" })}
        >
          <div className="text-center">
            ສົ່ງລົ້ມເຫຼວ
          </div>
        </Card>
      </div>
      <div style={{ marginTop: -40 }}>
        {tabActive === "completed" && <ItemCompleted />}
        {tabActive === "itemCancel" && <ItemCancel />}
      </div>
    </>
  );
}
