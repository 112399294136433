import React, { useState } from "react";
import _ from "lodash";
import useReactRouter from "use-react-router";
import LOGO from "../../../../img/anousith.png";
import { TAB_MENU_LIST } from "../../../../routes/app";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import {
  chargeOnShop,
  formatDateTime,
  currency,
  detectPhoneNumber,
} from "../../../../helper";
import { LIST_SHIPPER_ITEM } from "../../apollo";
import { Card } from "react-bootstrap";
import TrackingBarcode from "../../../component/Barcodedetail";

export default function DetailDataList() {
  const { history, match } = useReactRouter();
  const ID = parseInt(match?.params?._id);
  const [_item, setResult] = useState();
  const [fetchData, { data: result }] = useLazyQuery(LIST_SHIPPER_ITEM, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          _id: ID,
        },
      },
    });
    setResult(result?.items?.data);
  }, [result]);

  const message = "ສະບາຍດີ";

  return (
    <>
      <div className="appHeader text-light border-0 mr-0">
        <div style={{ flex: 1 }} className="text-left">
          <button
            className="btn text-white"
            onClick={() => history.push(`${TAB_MENU_LIST}/1`)}
          >
            <i className="fa fa-chevron-left fs-4" />
          </button>
        </div>
        <b className="text-white">ລາຍລະອຽດ</b>
        <div
          className="text-white pageTitle text-right text-nowrap pr-0"
          style={{ flex: 1 }}
        ></div>
      </div>
      <div className="container" style={{ marginTop: "70px" }}>
        <Card
          className="col-12 p-2"
          style={{
            marginTop: 70,
            boxShadow: "0px 3px 5px 3px rgba(0, 0, 0, 0.15)",
            borderRadius: 20,
          }}
        >
          <div className="justify-content-center d-flex align-items-center mb-3">
            <img
              src={LOGO}
              alt="logo"
              className="logo"
              style={{ width: "60%" }}
            />
          </div>
          {_item &&
            _item?.map((item) => (
              <>
                <div className="text-center text-dark mb-1">
                  <TrackingBarcode trackingNumber={item?.trackingId} />
                </div>
                <div className="d-flex justify-content-between">
                  <h6 className="bold-nato-sans text-right text-nowrap">
                    ວັນທີສ້າງ: {formatDateTime(item?.createdDate) || " "}
                  </h6>
                  <h6 className="bold-nato-sans text-right text-nowrap">
                    ສົ່ງສຳເລັດ:{" "}
                    {formatDateTime(item?.deliveryCompletedDate) || " "}
                  </h6>
                </div>
                <div className="custom-card">
                  <div className="row">
                    <div className="col-4">
                      <div className="custom-inner-card">
                        <h1 className="custom-inner-text">ຈາກ</h1>
                      </div>
                    </div>
                    <div
                      className="col-8 text-dark"
                      style={{
                        fontWeight: "bold",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      ຊື່ຜູ້ຝາກ: {item?.customer?.full_name || " "}
                      <div>
                        <a
                          className="text-link text-dark"
                          target="_blank"
                          href={`https://wa.me/${detectPhoneNumber(
                            item?.customer?.contact_info
                          )}?text=${message?.replace(
                            /<br\s*[\/]?>/gi,
                            " "
                          )} trackingID:${item?.trackingId}`}
                        >
                          <span className="bold-nato-sans">
                            020 {item?.customer?.contact_info || "xxxxxxxx"}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="custom-divider"></div>
                  <div className="custom-row">
                    <div className="custom-col-3 custom-text-dark text-center">
                      <h2>ຕົ້ນທາງ:</h2>
                    </div>
                    <h3 className="custom-col-8">
                      {item?.originBranch?.title || "ບໍ່ມີຂໍ້ມູນ"}
                    </h3>
                  </div>
                </div>
                <div
                  className="text-center blinking-arrow"
                  style={{ fontWeight: "bold" }}
                >
                  ⌵
                </div>
                <div className="custom-card mb-2">
                  <div className="row">
                    <div className="col-4">
                      <div className="custom-inner-card">
                        <h1 className="custom-inner-text">ເຖີງ</h1>
                      </div>
                    </div>
                    <div
                      className="col-8 text-dark"
                      style={{
                        fontWeight: "bold",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      ຊື່ຜູ້ຮັບ: {item?.receiverName || " "}{" "}
                      <div>
                        <a
                          className="text-link text-dark"
                          target="_blank"
                          href={`https://wa.me/${detectPhoneNumber(
                            item?.receiverPhone
                          )}?text=${message?.replace(
                            /<br\s*[\/]?>/gi,
                            " "
                          )} trackingID:${item?.trackingId}`}
                        >
                          <span className="bold-nato-sans">
                            020 {item?.receiverPhone || "xxxxxxxx"}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="custom-divider"></div>
                  <div className="custom-row">
                    <div className="custom-col-3 custom-text-dark text-center">
                      <h2>ປາຍທາງ:</h2>
                    </div>
                    <h3 className="custom-col-8">
                      {item?.destBranch?.title || "ບໍ່ມີຂໍ້ມູນ"}
                    </h3>
                  </div>
                </div>
                <div
                  className="d-flex text-dark justify-content-around"
                  style={{ fontWeight: "bold" }}
                >
                  <span>COD: {currency(item?.itemValueKIP || 0)} ₭</span>
                  <span>{currency(item?.itemValueTHB || 0)} ฿</span>
                  <span>{currency(item?.itemValueUSD || 0)} $</span>
                </div>
                <hr className="dashed" />
                <div className="d-flex text-dark justify-content-around">
                  <strong>ຄ່າບໍລິການ:</strong>
                  <h3 className="m-0">
                    {currency(item?.realDeliveryPrice || 0)} ₭
                  </h3>{" "}
                  <strong>ລວມ: </strong>
                  {(item?.chargeOnShop === 1 && item?.realValueKIP) ||
                  (item?.chargeOnShop === 0 && item?.realValueKIP) || (item?.chargeOnShop === 0) ? (
                    <h3>
                      {currency(
                        item?.itemValueKIP + item?.realDeliveryPrice || 0
                      )}{" "}
                      ₭
                    </h3>
                  ) : <h3>0 ₭</h3>}
                </div>
                <hr className="dashed" />
                <div className="text-center text-dark">
                  <h1>{chargeOnShop(item?.chargeOnShop)}</h1>
                </div>
              </>
            ))}
        </Card>
        <br />
      </div>
    </>
  );
}
