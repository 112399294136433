import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import _ from "lodash";
import Location from "../../../img/map_location.png";
import { LIST_SHIPPER_CONFIRMED } from "../../home/apollo";

export default function Locations({ data }) {
	const [show, setShow] = useState(false);

	return (
		<React.Fragment>
			<button
				type="button"
				className="btn btn-sm mt-1 p-1 w-100"
				style={{ borderBlockColor: "#B7B7B7", borderWidth: 1, backgroundColor: "#FAFAFA" }}
				onClick={() => setShow(true)}
			>
				<b style={{ fontWeight: "bold", color: "grey" }}>Location<i className="fa fa-map-marker ms-1 text-danger"/></b>
			</button>
			<Modal
				centered
				show={show}
				onHide={() => setShow(false)} 
				animation={false}
				backdrop="static"
				size="lx"
			>
				<Modal.Header closeButton><div ><img src={Location} style={{ width: 25 }} />ທີ່ຢູ່ປາຍທາງ</div></Modal.Header>
				<Modal.Body style={{ height: 300 }}>
					<div className="gmap_canvas">
						<iframe
							width={"100%"}
							height={300}
							id="gmap_canvas"
							src={`https://maps.google.com/maps?q=${data?.mapLat
								? data?.mapLat : "-"
								},${data?.mapLong
									? data?.mapLong : "-"
								}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
							frameBorder={0}
							scrolling="no"
							marginHeight={0}
							marginWidth={0}
						/>
						<br />
						<style
							dangerouslySetInnerHTML={{
								__html:
									".mapouter{position:relative;text-align:right;height:100%;width:100%;}",
							}}
						/>
						<style
							dangerouslySetInnerHTML={{
								__html:
									".gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}",
							}}
						/>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
}
